import { Pane } from 'evergreen-ui'
import { Types, PaymentMethodCard } from 'lib'
import GuarantorActionItemsCard from 'components/guarantor-action-items-card'

export type Props = {
  guarantorId: string
}

const GuarantorPaymentInfo = ({ guarantorId }: Props) => (
  <Pane padding={16} display="grid" gridGap={16}>
    <PaymentMethodCard guarantorId={guarantorId} elevation={0} allowDelete attemptScheduledPayments />
    <GuarantorActionItemsCard
      guarantorId={guarantorId}
      items={[
        Types.GuarantorActionItem.NOTIFICATION,
        Types.GuarantorActionItem.OUTREACH,
        Types.GuarantorActionItem.CHARGE,
        Types.GuarantorActionItem.ENROLL_INSTALLMENTS
      ]}
      elevation={0}
    />
  </Pane>
)

export default GuarantorPaymentInfo
