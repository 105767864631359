import { createContext, useContext, useEffect } from 'react'

import { useQuery, useMutation } from '@apollo/client'
import { GET_GLOBAL } from 'graphql/_global'
import { SET_CURRENT_GROUP_ID } from 'graphql/_header'
import { toaster } from 'evergreen-ui'

import { Types, FullScreen, Spinner, useToken, parseUserRoleFromToken } from 'lib'

type GlobalContext = Types.Global & {
  role: Types.UserRole
}

const Context = createContext<GlobalContext>({ role: Types.UserRole.BASIC } as GlobalContext)
export const useGlobal = () => useContext(Context)

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const { tokenResult, refreshToken } = useToken()
  const { loading, data } = useQuery<Types.Global>(GET_GLOBAL, { skip: !tokenResult })

  const [setGroup] = useMutation<Types.SetCurrentGroupId, Types.SetCurrentGroupIdVariables>(SET_CURRENT_GROUP_ID, {
    onCompleted: async (data) => {
      await refreshToken()
      toaster.success(`Set group to ${data?.setCurrentGroupId}`)
    },
    onError: () => {
      toaster.danger(`Unable to set group`)
    }
  })

  // Needs to occur in client so we can refresh token
  useEffect(() => {
    if (!tokenResult?.claims.currentGroupId && data?.meUser.groups.length) {
      setGroup({ variables: { groupId: data.meUser.groups[0].id } })
    }
  }, [tokenResult?.claims.currentGroupId, data?.meUser.groups])

  if (loading || !data || !tokenResult)
    return (
      <FullScreen display="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </FullScreen>
    )

  const role = parseUserRoleFromToken(tokenResult.claims.role)

  return <Context.Provider value={{ ...data, role }}>{children}</Context.Provider>
}
