import gql from 'graphql-tag'

export const GET_CHARGE_SHEET = gql`
  query ChargeSheet($chargeId: ID!) {
    charge(chargeId: $chargeId, expandInvoice: true) {
      id
      status
      date
      payoutDate
      patientFee
      grossAmount
      balanceAmount
      refundedAmount
      disputedAmount
      practiceFee
      netAmount
      last4
      institution
      recorded
      transaction {
        id
        type
        stripeId_subscription
        writebackMetrics {
          id
          postDate
          status
          totalPosted
          paymentsTotal
          adjustmentsTotal
          allocatedTotal
          unallocatedTotal
          payments {
            id
            status
            amount
            type
            pms_procedureId
            createdAt
          }
        }
        installmentPlanId
      }
      contact {
        id
        email
        phone

        code
        name {
          first
          last
        }
        dob {
          day
          month
          year
        }

        account {
          name
          phone
          siteUrl
          logoUrl
          postingSettings {
            enabled
            postingDate
            postingAmount
          }
          capabilities
        }
      }
      invoice {
        id
        code
        date
      }
    }
  }
`

export const UPDATE_CHARGE = gql`
  mutation UpdateCharge($chargeId: ID!, $recorded: Boolean) {
    updateCharge(chargeId: $chargeId, recorded: $recorded) {
      id
      recorded
    }
  }
`

export const WRITEBACK_CHARGE = gql`
  mutation WritebackCharge($chargeId: ID!) {
    writebackCharge(chargeId: $chargeId) {
      id
      writebackMetrics {
        id
        status
        totalPosted
      }
    }
  }
`

export const RESEND_RECEIPT = gql`
  mutation ResendReceipt($stripeChargeId: ID!) {
    resendReceipt(stripeChargeId: $stripeChargeId)
  }
`

export const GENERATE_CHARGE_RECEIPT_PDF = gql`
  mutation GeneratePracticeChargeReceiptPdf($chargeId: ID!) {
    generateChargeReceiptPdf(chargeId: $chargeId)
  }
`
