import { useState } from 'react'

import { useQuery, useMutation } from '@apollo/client'
import { GET_CHARGE_GUARANTOR_CARD, COMPLETE_GUARANTOR_CHARGE } from 'graphql/_charge-guarantor-card'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { CardProps, Heading, Pane, Table, Text, toaster, Paragraph } from 'evergreen-ui'
import {
  Types,
  Card,
  CardHeader,
  toDollarsFromCents,
  Spinner,
  PaymentMethodCard,
  Button,
  Checkbox,
  colors,
  FormError,
  Icon,
  TextInput
} from 'lib'

import { useModal } from 'components/modal-provider'
import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'

export interface Props extends CardProps {
  guarantorId: string
}

const ChargeGuarantorCard = ({ guarantorId, ...props }: Props) => {
  const { updateGuarantorSheetState } = useGuarantorSheetState()

  const showConfirm = useModal('confirm')

  const showChargeSheet = useModal('charge')

  const [isComplete, setIsComplete] = useState<Types.CompleteGuarantorCharge_chargeGuarantor | null>(null)

  const { data } = useQuery<Types.ChargeGuarantorCard, Types.ChargeGuarantorCardVariables>(GET_CHARGE_GUARANTOR_CARD, {
    variables: {
      id: guarantorId
    }
  })

  const [completeCharge, completeChargeStatus] = useMutation<
    Types.CompleteGuarantorCharge,
    Types.CompleteGuarantorChargeVariables
  >(COMPLETE_GUARANTOR_CHARGE, {
    update: (cache) => {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          balanceMetrics: (_existing, { DELETE }) => DELETE,
          balancesConnection: (_existing, { DELETE }) => DELETE
        }
      })

      cache.modify({
        id: cache.identify({ id: guarantorId, __typename: 'Contact' }),
        fields: {
          balance(cachedBalance) {
            return { ...cachedBalance, engagementDetails: undefined }
          }
        }
      })

      cache.gc()
    },
    onCompleted: (data) => setIsComplete(data.chargeGuarantor),
    onError: () => toaster.danger('Unable to complete charge')
  })

  if (!data)
    return (
      <Card {...props}>
        <Spinner delay={0} marginY={36} />
      </Card>
    )

  const {
    name: { first, last },
    email,
    stripe,
    balance: {
      metrics: { patientPortion }
    }
  } = data.contact

  const hasPaymentDetails = stripe?.defaultSource !== null
  const hasEmail = email !== null

  return !isComplete ? (
    <Card {...props} padding={0}>
      <CardHeader justifyContent="space-between">
        <Heading>Process Payment</Heading>
        <Text
          fontSize="14px"
          color="muted"
          fontStyle="italic"
          cursor="pointer"
          onClick={() =>
            updateGuarantorSheetState({
              path: Types.GuarantorPath.BILLING,
              billingPath: Types.GuarantorBillingPath.ACTIONS
            })
          }
        >
          Recent Balance: {toDollarsFromCents(patientPortion, 2)}
        </Text>
      </CardHeader>

      <Formik
        initialValues={{
          amount: ('' as unknown) as number,
          confirm: true,
          sendReceipt: hasEmail
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .positive('Amount must be positive')
            .min(5, 'Amount must be at least $5.00')
            .max(5000, 'Amount must be less than $10,000.00')
            .required('Amount is missing'),
          confirm: Yup.boolean().oneOf([true], 'Confirmation is required')
        })}
        onSubmit={({ amount, sendReceipt }) =>
          showConfirm({
            title: 'Confirm Charge',
            body: `Please confirm you would like to immediately charge this patient.`,
            onConfirm: () =>
              completeCharge({
                variables: {
                  guarantorId,
                  amount: Math.round(amount * 100),
                  sendReceipt
                }
              })
          })
        }
      >
        {({ values, dirty, isValid }) => {
          const amountInCents = values.amount * 100

          const isCredit = typeof stripe?.defaultSource?.isCredit === 'boolean' ? stripe.defaultSource.isCredit : true

          const { payments_patient_cc, payments_patient_ach, payments_patient_30 } = data.contact.account.feeSchedule

          const patientVarRate = (isCredit ? payments_patient_cc : payments_patient_ach) / 100
          const patientFixed = isCredit && payments_patient_30 ? 30 : 0
          const patientFeeTotal = Math.ceil(amountInCents ? amountInCents * (patientVarRate / 100) + patientFixed : 0)

          const feeString =
            patientFeeTotal > 0
              ? `(${patientVarRate ? `${patientVarRate}%` : ''}${patientVarRate && patientFixed ? ' + ' : ''}${
                  patientFixed ? toDollarsFromCents(patientFixed, 2) : ''
                })`
              : null

          return (
            <Form>
              <Pane margin={16} display="grid" gridGap={16}>
                <PaymentMethodCard guarantorId={guarantorId} elevation={0} isCreditInitial hideTabs />

                <Card elevation={0} padding={0}>
                  <CardHeader>
                    <Heading>Charge Now</Heading>
                  </CardHeader>
                  <Pane paddingTop={8} paddingBottom={patientFeeTotal > 0 ? 0 : 8}>
                    <Table>
                      <Table.Row borderBottom="none" paddingX={4}>
                        <Table.TextCell display="flex" alignItems="center" textProps={{ size: 500 }}>
                          Amount
                        </Table.TextCell>
                        <Table.Cell display="flex" alignItems="center">
                          <TextInput
                            name="amount"
                            type="number"
                            step="0.01"
                            width="100%"
                            textAlign="right"
                            placeholder={toDollarsFromCents(Math.max(patientPortion, 0), 2)}
                            marginBottom={0}
                            height={36}
                            data-cy="card-charge-amount-input"
                          />
                        </Table.Cell>
                      </Table.Row>

                      {patientFeeTotal > 0 && (
                        <>
                          <Table.Row paddingX={4}>
                            <Table.TextCell
                              display="flex"
                              alignItems="center"
                              flexBasis={140}
                              paddingRight={0}
                              flexGrow={0}
                              flexShrink={0}
                              textProps={{ size: 500 }}
                            >
                              Convenience Fee
                            </Table.TextCell>
                            <Table.TextCell textProps={{ textAlign: 'right' }}>
                              <Text fontSize="14px" marginRight={4}>
                                {toDollarsFromCents(patientFeeTotal, 2)}
                              </Text>
                              {feeString && (
                                <Text fontSize="14px" color="muted" fontStyle="italic">
                                  {feeString}
                                </Text>
                              )}
                            </Table.TextCell>
                          </Table.Row>

                          <Table.Row borderBottom="none" paddingX={4}>
                            <Table.Cell display="flex" alignItems="center">
                              <Heading>Total</Heading>
                            </Table.Cell>
                            <Table.TextCell textProps={{ fontSize: '14px', textAlign: 'right' }}>
                              {toDollarsFromCents(amountInCents + patientFeeTotal, 2)}
                            </Table.TextCell>
                          </Table.Row>
                        </>
                      )}
                    </Table>
                  </Pane>
                </Card>
                <Pane paddingX={8}>
                  <Checkbox
                    name="confirm"
                    label="The patient has authorized me to charge the above total using this payment method"
                  />
                  {hasEmail && <Checkbox name="sendReceipt" label="Deliver email receipt" marginBottom={0} />}
                  <FormError />
                </Pane>
              </Pane>
              <Pane
                padding={16}
                borderTop={`solid 1px ${colors.border.muted}`}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  appearance="primary"
                  disabled={!hasPaymentDetails || !dirty || !isValid}
                  height={48}
                  isLoading={completeChargeStatus.loading}
                  data-cy="card-charge-now-button"
                >
                  {hasPaymentDetails ? 'Charge Now' : 'Update Payment Details'}
                </Button>
              </Pane>
            </Form>
          )
        }}
      </Formik>
    </Card>
  ) : (
    <Card margin={16} alignItems="center" padding={36} elevation={0} textAlign="center" data-cy="charge-message">
      <Icon icon={['fad', 'check']} color="success" size="5x" marginBottom={16} />
      <Heading marginBottom={16}>Charge Successful!</Heading>

      <Paragraph size={500}>
        {first} {last} has been successfully charged {toDollarsFromCents(isComplete.balanceAmount, 2)}
      </Paragraph>

      <Button
        iconBefore={['far', 'receipt']}
        height={48}
        marginY={16}
        onClick={() => showChargeSheet({ chargeId: isComplete.id })}
      >
        View Charge
      </Button>

      <Paragraph>
        Charged by mistake? Click View Charge to issue a refund, download a receipt, and view more details.
      </Paragraph>
    </Card>
  )
}

export default ChargeGuarantorCard
