import { useState, useEffect } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { capital } from 'case'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'

import {
  GET_PAYMENT_METHOD_CARD,
  UPDATE_GUARANTOR_PAYMENT_METHOD,
  DELETE_GUARANTOR_PAYMENT_METHOD
} from 'lib/graphql/_payment-method-card'
import { PlaidLink } from 'react-plaid-link'
import { Heading, Pane, Text, Badge, CardProps, toaster, Elevation, Strong } from 'evergreen-ui'

import { Types, IconButton, Card, CardHeader, CardElement, Icon, Button, Spinner, ConfirmDialog, colors, mc } from 'lib'

import { useLibConfig } from './'

export type Props = CardProps & {
  guarantorId?: string
  elevation?: Elevation
  hideTabs?: boolean
  hideHeader?: boolean
  useCache?: boolean
  required?: boolean
  isCreditInitial?: boolean
  isDependent?: boolean
  allowDelete?: boolean
  attemptScheduledPayments?: boolean
  setPlaidState?: React.Dispatch<
    React.SetStateAction<
      | {
          token: string
          accountId: string
        }
      | undefined
    >
  >
}
const PaymentMethodCard = ({
  guarantorId,
  elevation = 1,
  hideTabs,
  hideHeader,
  useCache,
  required,
  isCreditInitial = true,
  isDependent,
  allowDelete = false,
  attemptScheduledPayments = false,
  setPlaidState,
  ...props
}: Props) => {
  const apolloClient = useApolloClient()
  const stripe = useStripe()
  const elements = useElements()
  const {
    plaid: { env, publicKey }
  } = useLibConfig()

  const plaidConfig = {
    clientName: `${mc.name} App`,
    env,
    product: ['auth'],
    selectAccount: true,
    publicKey
  }

  const [plaidLocalState, setPlaidLocalState] = useState(
    (undefined as unknown) as { token: string; accountId: string; accountName: string; institutionName: string }
  )

  const [isSaving, setIsSaving] = useState(false)

  const [isCredit, setIsCredit] = useState(isCreditInitial)
  const [isPlaidLoading, setIsPlaidLoading] = useState(false)
  const [isDeleteConfirmShown, setIsDeleteConfirmShown] = useState(false)
  const [isUpdateConfirmShown, setIsUpdateConfirmShown] = useState(false)

  const skipRetrieval = !guarantorId

  const { loading, data } = useQuery<Types.PaymentMethodCard, Types.PaymentMethodCardVariables>(
    GET_PAYMENT_METHOD_CARD,
    {
      skip: skipRetrieval,
      variables: {
        guarantorId: guarantorId!,
        allowDelete,
        attemptScheduledPayments
      },
      client: apolloClient,
      fetchPolicy: useCache ? 'cache-only' : 'cache-first'
    }
  )

  const source = data?.contact?.stripe?.defaultSource
  // Default to true if there is no attempt at source retrieval or if cached result has no default Source
  const [editing, setEditing] = useState(skipRetrieval || (loading === false && !source))

  useEffect(() => {
    // Use instead of onCompleted to avoid lexical scoping error
    // Triggers after query completion or cache retrieval completion
    if (loading === false) {
      if (!editing && !source) setEditing(true)
    }
  }, [loading])

  const plaidLinkProps = {
    ...plaidConfig,
    onSuccess: (token: string, metadata: { account: { name: string; id: string }; institution: { name: string } }) => {
      if (setPlaidState) setPlaidState({ token, accountId: metadata.account.id })
      setPlaidLocalState({
        token,
        accountId: metadata.account.id,
        accountName: metadata.account.name,
        institutionName: metadata.institution.name
      })
    },
    onExit: () => setIsPlaidLoading(false),
    style: { padding: 0, border: 'none' }
  }

  const [updateMethod] = useMutation<Types.UpdateGuarantorPaymentMethod, Types.UpdateGuarantorPaymentMethodVariables>(
    UPDATE_GUARANTOR_PAYMENT_METHOD,
    {
      client: apolloClient,
      update: (cache, { data }) => {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            failedPatients: (_existing, { DELETE }) => DELETE,
            invoicesConnection: (_existing, { DELETE }) => DELETE,
          }
        })

        if (data) {
          cache.modify({
            id: cache.identify({ id: data.updateGuarantorPaymentMethod?.contact?.id, __typename: 'Contact' }),
            fields: {
              stripe(cachedStripe) {
                return { ...cachedStripe, defaultSource: data.updateGuarantorPaymentMethod?.contact?.stripe?.defaultSource }
              }
            }
          })

          cache.evict({
            id: cache.identify({ id: data.updateGuarantorPaymentMethod?.contact?.id, __typename: 'Contact' }),
            fieldName: 'installmentPlans'
          })
        }

        cache.gc()
      },
      onCompleted: (updateData) => {
        setEditing(false)
        const hadFailedPayments = data?.contact.stripe.latestOpenInvoice || data?.contact.hasFailedScheduledPayment
        if (updateData?.updateGuarantorPaymentMethod?.contact?.stripe?.latestOpenInvoice || updateData?.updateGuarantorPaymentMethod?.error) {
          toaster.warning('Payment method still invalid. Please update.')
        } else if(attemptScheduledPayments && hadFailedPayments) {
          toaster.success('Payment method securely stored and account brought current.')
        } else toaster.success('Payment method securely stored.')
      },
      onError: (err) => {
        toaster.danger('Unable to update payment method', { description: err.message.replace('GraphQL error: ', '') })
      }
    }
  )

  const [deleteMethod, deleteMethodStatus] = useMutation<
    Types.DeleteGuarantorPaymentMethod,
    Types.DeleteGuarantorPaymentMethodVariables
  >(DELETE_GUARANTOR_PAYMENT_METHOD, {
    client: apolloClient,
    onCompleted: () => {
      setEditing(true)
      toaster.success('Payment method successfuly deleted')
    },
    onError: () => toaster.danger('Unable to delete payment method')
  })

  const updatePaymentMethod = async () => {
    try {
      setIsSaving(true)
      let paymentVariables

      if (isCredit) {
        if (!elements || !stripe) throw Error('Stripe not initialized')
        const stripeElement = elements?.getElement('card')
        if (!stripeElement) throw Error('Stripe element not present')

        const { token: stripeToken } = await stripe.createToken(stripeElement)

        if (!stripeToken) throw Error('Please include a valid credit card')
        paymentVariables = { stripeToken: stripeToken.id }
      } else {
        if (!plaidLocalState) throw Error('Please securely link your bank account')
        paymentVariables = {
          plaidToken: plaidLocalState.token,
          plaidAccountId: plaidLocalState.accountId
        }
      }
      if (!guarantorId) throw Error('Guarantor ID missing')
      
      await updateMethod({
        variables: {
          guarantorId,
          attemptScheduledPayments,
          ...paymentVariables
        }
      })
    } catch (err) {
      if (err instanceof Error) toaster.danger(err.message)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Card elevation={elevation} padding={0} {...props}>
      {!hideHeader && (
        <CardHeader justifyContent="space-between">
          <Heading size={500} flex={1}>
            {isDependent ? 'Guarantor ' : ''}Payment Details
          </Heading>
          <Badge color="neutral" position="relative" paddingLeft={20}>
            <Icon icon={['fas', 'lock']} fontSize="8px" position="absolute" top={-2} left={8} />
            PCI Secure
          </Badge>
        </CardHeader>
      )}
      {loading ? (
        <Pane padding={16} display="flex" alignItems="center">
          <Spinner size={32} delay={0} />
        </Pane>
      ) : editing ? (
        <>
          {!hideTabs && <PaymentTabs isCredit={isCredit} setIsCredit={setIsCredit} />}
          <Pane width="100%" padding={16}>
            {isCredit ? (
              <Pane display="flex" alignItems="center">
                <CardElement />
              </Pane>
            ) : plaidLocalState ? (
              <Pane display="flex" justifyContent="space-between" alignItems="center">
                <Text size={500} marginRight={8}>
                  <Icon icon={['fad', 'building-columns']} marginRight={8} />
                  <Strong size={500}>{plaidLocalState.institutionName}</Strong> - {plaidLocalState.accountName}
                </Text>
                <PlaidLink {...plaidLinkProps}>
                  <Button>Edit</Button>
                </PlaidLink>
              </Pane>
            ) : (
              <Pane textAlign="center" padding={8}>
                <PlaidLink {...plaidLinkProps}>
                  <Button
                    isLoading={isPlaidLoading}
                    iconBefore={['fas', 'building-columns']}
                    marginBottom={4}
                    onClick={() => {
                      setIsPlaidLoading(true)
                    }}
                  >
                    Link Bank Account
                  </Button>
                </PlaidLink>

                <Text display="block" size={300}>
                  Securely link your bank account to pay
                  <br />
                  via ACH Direct Debit
                </Text>
              </Pane>
            )}
          </Pane>
          {(!required || source) && (
            <Pane display="flex" justifyContent="flex-end" padding={16} borderTop={`solid 1px ${colors.border.muted}`}>
              {source && (
                <Button marginRight={8} onClick={() => setEditing(false)}>
                  Cancel
                </Button>
              )}
              {!required && (
                <Button
                  intent="success"
                  isLoading={isSaving}
                  onClick={async () => {
                    const creditForbidden = data && isCredit && !data.contact.restrictedPaymentMethods.cc
                    const achForbidden = data && !isCredit && !data.contact.restrictedPaymentMethods.ach
                    const hasFailedPayments = data?.contact.stripe.latestOpenInvoice || data?.contact.hasFailedScheduledPayment
                    if (creditForbidden || achForbidden) {
                      toaster.danger('Cannot Update Payment Method', {
                        description: 'Payment method not compatible with Payment Plan rules.'
                      })
                    } else if (attemptScheduledPayments && hasFailedPayments) {
                      setIsUpdateConfirmShown(true)
                    } else {
                      await updatePaymentMethod()
                    }
                  }}
                  data-cy='save-payment-method-card-button'
                >
                  Save Payment Method
                </Button>
              )}
              <ConfirmDialog
                isShown={isUpdateConfirmShown}
                setIsShown={setIsUpdateConfirmShown}
                onConfirm={updatePaymentMethod}
                title="Charge Past Due Invoices"
                body="This guarantor has unpaid and past due installment or membership fees. By updating the payment method, we will attempt to charge all previous unpaid invoices and bring plans current. If you would not like to charge past due invoices, please terminate plans prior to updating the payment method."
                confirmLabel="Update & Charge"
              />
            </Pane>
          )}
        </>
      ) : (
        source && (
          <Pane padding={16} display="flex" alignItems="center">
            <Pane display="flex" flexGrow={1}>
              <Icon icon={['fad', source.isCredit ? 'credit-card' : 'building-columns']} marginRight={8} />
              <Text size={500} display="block" flex={1}>
                {capital(source.institution)}
              </Text>
              <Text size={500} display="block" marginLeft={8} textAlign="right">
                **{source.last4}
              </Text>
            </Pane>

            {guarantorId && (
              <>
                <Button
                  onClick={() => {
                    setIsCredit(source.isCredit)
                    setEditing(true)
                  }}
                  marginLeft={16}
                >
                  Update
                </Button>
                {allowDelete && (
                  <>
                    <ConfirmDialog
                      isShown={isDeleteConfirmShown}
                      setIsShown={setIsDeleteConfirmShown}
                      onConfirm={() => deleteMethod({ variables: { guarantorId } })}
                      title="Confirm Payment Method Removal"
                      body="Please confirm that you would like to permanently delete this payment method stored on file. You can always add a new payment method, but any recurring scheduled charges will fail due to a lack of payment method on file."
                    />
                    <IconButton
                      marginLeft={8}
                      icon={['fas', 'trash-alt']}
                      isLoading={deleteMethodStatus.loading}
                      onClick={() => {
                        if (data?.contact.stripe.hasSubscriptions || data?.contact.hasOngoingInstallmentPlan) {
                          toaster.danger('Cannot Delete Payment Method', {
                            description: 'In order to remove the payment method on file, please terminate any active membership or payment plans.'
                          })
                        } else setIsDeleteConfirmShown(true)
                      }}
                    />
                  </>
                )}
              </>
            )}
          </Pane>
        )
      )}
    </Card>
  )
}

export default PaymentMethodCard

type PaymentTabsProps = {
  isCredit: boolean
  setIsCredit: (isCredit: boolean) => void
}

const PaymentTabs = ({ isCredit, setIsCredit }: PaymentTabsProps) => {
  const tabProps = {
    height: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'content-box' as const,
    flex: 1,
    cursor: 'pointer'
  }

  return (
    <Pane display="flex" height="32px">
      <Pane
        {...tabProps}
        onClick={() => setIsCredit(true)}
        background={isCredit ? 'white' : colors.neutral.lightest}
        borderBottom={isCredit ? 'none' : `solid 1px ${colors.border.muted}`}
      >
        <Icon marginRight={8} color={isCredit ? 'default' : colors.neutral.lightest} icon={['fas', 'check-circle']} />
        <Text marginRight={16} size={300} color={isCredit ? colors.neutral.base : 'muted'}>
          Credit Card
        </Text>
      </Pane>
      <Pane width={1} backgroundColor={colors.border.muted} />
      <Pane
        {...tabProps}
        cursor="pointer"
        onClick={() => setIsCredit(false)}
        background={isCredit ? colors.neutral.lightest : 'white'}
        borderBottom={isCredit ? `solid 1px ${colors.border.muted}` : 'none'}
      >
        <Text marginLeft={8} size={300} color={isCredit ? 'muted' : colors.neutral.base}>
          Bank ACH
        </Text>
        <Icon marginLeft={8} color={isCredit ? colors.neutral.lightest : 'default'} icon={['fas', 'check-circle']} />
      </Pane>
    </Pane>
  )
}
