import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery, useMutation } from '@apollo/client'
import { GET_ONBOARDING_COMPLETE, SUBMIT_ONBOARDING_COMPLETE } from 'graphql/_onboarding'

import { Pane, Paragraph, Heading, toaster } from 'evergreen-ui'
import { Types, Card, CardHeader, Icon, Button, Spinner, AddressCard, useToken, mc } from 'lib'

export type Props = {
  prev: string
  setIsForward: React.Dispatch<React.SetStateAction<boolean>>
}

const OnboardingComplete = ({ prev, setIsForward }: Props) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const { refreshToken } = useToken()

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery<Types.OnboardingComplete>(
    GET_ONBOARDING_COMPLETE
  )

  const [submit] = useMutation<Types.SubmitOnboardingComplete>(SUBMIT_ONBOARDING_COMPLETE, {
    onCompleted: async () => {
      await refreshToken()
      setIsForward(true)
      history.push('/')
    },
    onError: () => {
      toaster.danger('Unable to complete onboarding')
      setLoading(false)
    }
  })

  return queryLoading || queryError || !queryData ? (
    <Spinner paddingTop={0} delay={1200} />
  ) : (
    <Card padding={0}>
      <CardHeader>
        <Heading size={500} flexGrow={1} textAlign="center">
          Set up {mc.name} for your Practice
        </Heading>
      </CardHeader>

      <Pane padding={24} display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <Icon icon={['fad', 'check']} color="success" size="3x" marginBottom={16} />
        <Paragraph size={500} marginBottom={16}>
          Thank you {queryData.meUser?.firstName}! Please review your key account details. You can edit them at any time
          in the {mc.name} Portal or by clicking back.
        </Paragraph>
        <Paragraph size={500} marginBottom={24}>
          You will create your plans and launch your program in the {mc.name} Portal.
        </Paragraph>
        <AddressCard address={queryData.firstAddress} width="100%" textAlign="left" />
      </Pane>

      <Pane zIndex={1} elevation={0} padding={16} flexShrink={0} display="flex" justifyContent="space-between">
        <Button
          appearance="minimal"
          height={48}
          justifyContent="center"
          iconBefore={['far', 'chevron-left']}
          onClick={() => {
            setIsForward(false)
            history.push(prev)
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setLoading(true)
            submit()
          }}
          appearance="primary"
          height={48}
          justifyContent="center"
          iconAfter={['far', 'chevron-right']}
          isLoading={loading}
        >
          Enter Portal
        </Button>
      </Pane>
    </Card>
  )
}

export default OnboardingComplete
