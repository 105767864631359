import { useHistory } from 'react-router-dom'
import { IconName } from '@fortawesome/fontawesome-svg-core'

import { Pane, Heading, Alert, Text, Badge } from 'evergreen-ui'
import { Icon, colors, Spinner } from 'lib'

export type Props = {
  icon: IconName
  title: string
  body?: string
  badge?: string
  badgeColor?: 'blue' | 'green' | 'red' | 'orange' | 'yellow' | 'neutral'
  to?: string
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
  intent?: 'default' | 'success' | 'warning' | 'danger'
}

const CTA = ({ intent, icon, title, body, badge, badgeColor = 'neutral', to, disabled, onClick, loading }: Props) => {
  const history = useHistory()
  if (to && onClick) throw Error(`CTA can not have 'to' and 'onClick' props`)
  if (loading) disabled = true

  return (
    <Alert
      intent={intent !== 'default' ? intent : undefined}
      hasIcon={false}
      hoverElevation={disabled ? undefined : 1}
      cursor={loading ? 'default' : disabled ? 'not-allowed' : 'pointer'}
      onClick={disabled ? () => null : to ? () => history.push(to) : onClick}
      background={disabled ? colors.neutral.lightest : 'white'}
      data-cy="cta"
    >
      {disabled && (
        <Pane background={colors.neutral.base} width="3px" height="100%" position="absolute" top={0} left={0} />
      )}
      <Pane display="flex" alignItems="center">
        <Pane width="52px" display="flex" justifyContent="center" alignItems="center">
          <Icon icon={['fad', icon]} alignSelf="center" size="2x" marginRight={20} marginLeft={8} color={intent} />
        </Pane>
        <Pane flexGrow={1} display="flex" flexDirection="column" justifyContent="center">
          <Heading color={disabled ? colors.neutral.muted : colors.neutral.base}>{title}</Heading>
          {body && (
            <Text color="muted" display="inline-block" marginTop={4}>
              {body}
            </Text>
          )}
        </Pane>

        {badge && <Badge color={badgeColor}>{badge}</Badge>}

        {loading ? (
          <Spinner delay={0} width="18px" height="18px" />
        ) : (
          <Icon
            icon={['far', disabled ? 'ban' : 'chevron-right']}
            color={intent}
            size="lg"
            marginRight={8}
            marginLeft={14}
          />
        )}
      </Pane>
    </Alert>
  )
}

export default CTA
