import { useQuery, useMutation } from '@apollo/client'
import { GET_GUARANTOR_ACTION_ITEMS, END_GUARANTOR_FLOW } from 'graphql/_guarantor-action-items'

import { Pane, Heading, toaster, CardProps } from 'evergreen-ui'
import { Types, Card, CardHeader, Icon, Spinner, Divider, mc } from 'lib'

import ActionItem from 'components/action-item'
import { Props as ActionItemCTAProps } from 'components/action-item/cta'
import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'
import { useGlobal } from 'components/global-provider'
import { useModal } from 'components/modal-provider'
import { intersection } from 'lodash'

export interface Props extends CardProps {
  guarantorId: string
  items: Types.GuarantorActionItem[]
  header?: string | null
}

const GuarantorActionItemsCard = ({ guarantorId, items, header = 'Action Items', ...props }: Props) => {
  const showConfirm = useModal('confirm')
  const showSunbitTextDialog = useModal('sunbitText')

  const global = useGlobal()
  const capabilities = global.account.capabilities
  const anonymize = global.meUser.google.anonymize

  const { updateGuarantorSheetState } = useGuarantorSheetState()

  const { data } = useQuery<Types.GuarantorActionItems, Types.GuarantorActionItemsVariables>(
    GET_GUARANTOR_ACTION_ITEMS,
    {
      variables: {
        id: guarantorId
      }
    }
  )

  const [endFlow, endFlowStatus] = useMutation<Types.EndGuarantorFlow, Types.EndGuarantorFlowVariables>(
    END_GUARANTOR_FLOW,
    {
      variables: {
        guarantorId: guarantorId
      },
      update: (cache) => {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            balanceMetrics: (_existing, { DELETE }) => DELETE,
            balanceAssistant: (_existing, { DELETE }) => DELETE,
            balancesConnection: (_existing, { DELETE }) => DELETE
          }
        })

        const cacheId = cache.identify({ id: guarantorId, __typename: 'Contact' })
        cache.evict({ id: cacheId, fieldName: 'outreach' })
        cache.evict({ id: cacheId, fieldName: 'balance' })

        cache.gc()
      },
      onCompleted: () => {
        toaster.success(`Outreach successfully cancelled for ${first} ${last}`)
      },
      onError: () => toaster.danger('Unable to cancel outreach')
    }
  )

  const Header = () =>
    header ? (
      <CardHeader>
        <Pane display="flex">
          <Icon fontSize={20} marginRight={12} icon={['fad', 'tasks']} />
          <Heading size={500}>{header}</Heading>
        </Pane>
      </CardHeader>
    ) : null

  if (!data)
    return (
      <Card padding={0} {...props}>
        <Header />
        <Pane display="flex" alignItems="center" height="100%" paddingY={36}>
          <Spinner delay={0} />
        </Pane>
      </Card>
    )

  const guarantor = data.contact

  const {
    name: { first, last },
    code,
    dob,
    stripe,
    email,
    phone
  } = guarantor

  const dobQueryString = dob ? `dobDay=${dob.day}&dobMonth=${dob.month}&dobYear=${dob.year}` : ''

  const balance = guarantor.balance
  const balanceStatus = balance.engagementDetails?.status
  const blockedReasons = balance.engagementDetails?.blockedReasons

  const mappedItems: ActionItemCTAProps[] = []

  if (items.includes(Types.GuarantorActionItem.PROFILE_INFO)) {
    let title: string | null = null

    if (blockedReasons?.includes(Types.BalanceBlockedReason.CONTACT_FAILED)) title = 'Update Failed Contact Info'
    else if (!email && !phone) title = 'Add Missing Contact Info'
    else if (!dob) title = 'Add Missing DOB'

    if (title)
      mappedItems.push({
        icon: 'id-card' as const,
        intent: 'default' as const,
        title,
        onClick: () => updateGuarantorSheetState({ path: Types.GuarantorPath.PROFILE })
      })
  }

  if (items.includes(Types.GuarantorActionItem.NOTIFICATION))
    mappedItems.push({
      icon: 'comments-alt-dollar' as const,
      intent: 'default' as const,
      title: 'Send Notification',
      disabled: !capabilities.includes(Types.AccountCapability.BILLING),
      onClick: () => updateGuarantorSheetState({ path: Types.GuarantorPath.NOTIFICATION })
    })

  if (items.includes(Types.GuarantorActionItem.OUTREACH) && balanceStatus === Types.BalanceEngagementStatus.ENGAGED)
    mappedItems.push({
      icon: 'ban' as const,
      intent: 'default' as const,
      title: 'Stop Outreach',
      loading: endFlowStatus.loading,
      disabled: !capabilities.includes(Types.AccountCapability.BILLING),
      onClick: () =>
        showConfirm({
          title: 'Confirm',
          body:
            'This patient is currently engaged for payment outreach.  Please confirm you would like to end outreach.  You can re-engage the patient at any time.',
          onConfirm: () => endFlow()
        })
    })

  if (
    items.includes(Types.GuarantorActionItem.OUTREACH) &&
    balanceStatus &&
    balanceStatus !== Types.BalanceEngagementStatus.ENGAGED
  ) {
    let title = ''
    const baseComponent = {
      icon: 'paper-plane' as const,
      intent: 'default' as const,
      disabled: !capabilities.includes(Types.AccountCapability.BILLING),
      onClick: () => updateGuarantorSheetState({ path: Types.GuarantorPath.START_OUTREACH })
    }

    if (balanceStatus !== Types.BalanceEngagementStatus.BLOCKED) {
      title = 'Start Billing Outreach'
    } else if (
      blockedReasons?.length &&
      intersection(blockedReasons, [
        Types.BalanceBlockedReason.CONTACT_FAILED,
        Types.BalanceBlockedReason.CONTACT_MISSING,
        Types.BalanceBlockedReason.DOB_MISSING
      ]).length === 0
    ) {
      title = 'Unblock & Start Outreach'
    }
    if (title) mappedItems.push({ ...baseComponent, title })
  }

  if (items.includes(Types.GuarantorActionItem.ENROLL_INSTALLMENTS))
    mappedItems.push({
      icon: 'file-chart-pie' as const,
      intent: 'default' as const,
      title: 'Start Payment Plan',
      onClick: () => updateGuarantorSheetState({ path: Types.GuarantorPath.ENROLL_INSTALLMENTS }),
      disabled: !capabilities.includes(Types.AccountCapability.INSTALLMENTS)
    })

  if (items.includes(Types.GuarantorActionItem.CHARGE))
    mappedItems.push({
      icon: 'badge-dollar' as const,
      intent: 'default' as const,
      title: 'Process Payment',
      onClick: () => updateGuarantorSheetState({ path: Types.GuarantorPath.CHARGE }),
      disabled: !capabilities.includes(Types.AccountCapability.CHARGES)
    })

  if (items.includes(Types.GuarantorActionItem.ENROLL_MEMBER) && !stripe?.subscriptionId)
    mappedItems.push({
      icon: 'files-medical' as const,
      intent: 'default' as const,
      title: 'Enroll Member',
      onClick: () => {
        if (dob) updateGuarantorSheetState({ path: Types.GuarantorPath.PLANS })
        else toaster.warning('Unable to enroll member missing DOB')
      },
      disabled: !capabilities.includes(Types.AccountCapability.MEMBERSHIP)
    })

  if (items.includes(Types.GuarantorActionItem.PAYMENT_INFO))
    mappedItems.push({
      icon: 'credit-card' as const,
      intent: 'default' as const,
      title: `${stripe?.defaultSource ? 'Update' : 'Add'} Payment Info`,
      onClick: () => updateGuarantorSheetState({ path: Types.GuarantorPath.PAYMENT_INFO })
    })

  if (items.includes(Types.GuarantorActionItem.PAY_PORTAL))
    mappedItems.push({
      icon: 'browser' as const,
      intent: 'default' as const,
      title: 'Visit Pay Portal',
      disabled: !capabilities.includes(Types.AccountCapability.BILLING),
      onClick: () =>
        window.open(
          `${mc.urls.payment}/${code}${dob ? `?${dobQueryString}` : ''}${anonymize ? '&demo=true' : ''}`,
          '_blank'
        )
    })

  if (items.includes(Types.GuarantorActionItem.RECEIPT_PORTAL) && stripe?.customerId)
    mappedItems.push({
      icon: 'receipt' as const,
      intent: 'default' as const,
      title: 'View Receipts',
      onClick: () =>
        window.open(
          `${mc.urls.payment}/${guarantor.code}?dest=receipts${dob ? `&${dobQueryString}` : ''}${
            anonymize ? '&demo=true' : ''
          }`,
          '_blank'
        )
    })

  if (items.includes(Types.GuarantorActionItem.SUNBIT_TEXT) && phone && guarantor.account.sunbitConfigurationId)
    mappedItems.push({
      icon: 'file-signature' as const,
      intent: 'default' as const,
      title: 'Text Sunbit Link',
      onClick: () => showSunbitTextDialog({ guarantor })
    })

  const enabledItems = mappedItems.filter((item) => !item.disabled)
  const disabledItems = capabilities.includes(Types.AccountCapability.UPSELLS)
    ? mappedItems.filter((item) => item.disabled)
    : []

  return mappedItems.length === 0 ? null : (
    <Card padding={0} {...props}>
      <Header />
      <Pane padding={12} display="grid" gap="12px">
        {enabledItems.map((mappedItem, i) => (
          <ActionItem.CTA key={i} {...mappedItem} />
        ))}
        {disabledItems.length > 0 && <Divider label="Inactive Features" marginY={4} />}
        {disabledItems.map((mappedItem, i) => (
          <ActionItem.CTA key={i} {...mappedItem} />
        ))}
      </Pane>
    </Card>
  )
}

export default GuarantorActionItemsCard
