import moment from 'moment'

import { CardProps, Pane, Text, Heading, Badge } from 'evergreen-ui'
import { Types, Card, Icon, toDollarsFromCents, colors } from 'lib'

import { useModal } from 'components/modal-provider'
import { getPrettyTransactionType } from 'utility/label-maps'

export interface Props extends CardProps {
  charge: Types.GuarantorBillingProcessed_contact_charges
}

const Charge = ({ charge, ...props }: Props) => {
  const { id, date, balanceAmount, refundedAmount, disputedAmount, transaction } = charge

  if (transaction === null) throw Error('Charge transaction is null')

  const showChargeSheet = useModal('charge')

  const label = getPrettyTransactionType(transaction)

  const refundedOrDisputed = refundedAmount > 0 || disputedAmount > 0

  return (
    <Card
      {...props}
      onClick={() => showChargeSheet({ chargeId: id })}
      paddingY={12}
      paddingX={16}
      hoverElevation={1}
      cursor="pointer"
      flexDirection="row"
      alignItems="center"
    >
      <Pane width="36px">
        <Icon icon={['fal', 'file-invoice-dollar']} color="success" size="lg" />
      </Pane>

      <Pane flexGrow={1}>
        <Text size={300} marginBottom={4}>
          {moment.unix(date).format('M/D/YYYY')}
        </Text>
        <Heading
          size={500}
          textDecoration={refundedOrDisputed ? 'line-through' : undefined}
          color={refundedOrDisputed ? colors.neutral.muted : colors.neutral.dark}
        >
          {toDollarsFromCents(balanceAmount, 2)}
        </Heading>
      </Pane>

      <Pane display="flex" flexDirection="column">
        <Badge color="green">{label}</Badge>
        {refundedAmount > 0 && (
          <Badge marginTop={4}>{refundedAmount >= balanceAmount ? 'Full' : 'Partial'} Refund</Badge>
        )}
        {disputedAmount > 0 && <Badge marginTop={4}>Dispute</Badge>}
      </Pane>

      <Icon marginLeft={14} lineHeight="14px" icon={['far', 'chevron-right']} color="success" />
    </Card>
  )
}

export default Charge
