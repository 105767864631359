import { useState, useEffect } from 'react'
import moment from 'moment'
import { Types } from 'lib'

import { Pane, Heading, Text, Elevation, toaster, Strong, PaneProps } from 'evergreen-ui'
import { Card } from 'lib'
import { Button } from 'lib'
import { CardHeader } from 'lib'
import { Icon } from 'lib'

import { PaymentMethodCard } from 'lib'
import { CouponCard } from 'lib'

import { SetDateDialog } from 'lib'

import { capital } from 'case'

export type Props = PaneProps & {
  accountId?: string
  elevation?: Elevation
  plan: {
    id: string
    name: string
    term: Types.PlanTerm
    price: number
  }
  setCouponCodeState: React.Dispatch<React.SetStateAction<string | undefined>>
  setPlaidState: React.Dispatch<
    React.SetStateAction<
      | {
          token: string
          accountId: string
        }
      | undefined
    >
  >
  billingCycleAnchorState?: number
  setBillingCycleAnchorState?: (billingCycleAnchor: number) => void
  patientName?: string
  guarantorId?: string
  isPortal?: boolean
  isDependent?: boolean
}

const MemberPaymentFields = ({
  accountId,
  elevation = 0,
  plan,
  setCouponCodeState: setParentCouponCodeState,
  setPlaidState,
  billingCycleAnchorState,
  setBillingCycleAnchorState,
  patientName,
  guarantorId,
  isPortal,
  isDependent,
  ...props
}: Props) => {
  const [showCoupon, setShowCoupon] = useState(false)
  const [couponState, setCouponState] = useState<Types.Coupon>()

  const [isSetDateDialogShown, setIsSetDateDialogShown] = useState(false)

  useEffect(() => {
    if (couponState) setParentCouponCodeState(couponState.id)
  }, [couponState, setParentCouponCodeState])

  const startMoment = billingCycleAnchorState ? moment.unix(billingCycleAnchorState) : moment()

  return (
    <>
      <SetDateDialog
        isShown={isSetDateDialogShown}
        setIsShown={setIsSetDateDialogShown}
        onSubmit={({ day, month, year }) => {
          if (!setBillingCycleAnchorState) throw Error(`Missing prop: 'setBillingCycleAnchorState'`)
          const anchorMoment = moment({ day, month: month - 1, year })
          setBillingCycleAnchorState && setBillingCycleAnchorState(anchorMoment.unix())
          toaster.success('Start date successfully updated')
        }}
        title="Change Start Date"
        body="Please enter the revised plan start date:"
      />
      <Pane display="flex" flexDirection="column" {...props}>
        <Card elevation={elevation} padding={0} marginBottom={16}>
          <CardHeader>
            <Heading size={500} flex={1} textAlign="center">
              Payment Summary
            </Heading>
          </CardHeader>
          <Pane padding={24} display="flex" flexDirection="column">
            {patientName && (
              <Text size={500} marginBottom={8}>
                <Strong size={500}>Name: </Strong>
                {patientName}
              </Text>
            )}
            <Text size={500} marginBottom={8}>
              <Strong size={500}>Plan: </Strong>
              {plan?.name}
            </Text>
            <Text size={500} marginBottom={8}>
              <Strong size={500}>Price: </Strong>
              {getShownPrice(plan?.price, plan?.term, couponState)}
            </Text>
            <Text size={500} marginBottom={8}>
              <Strong size={500}>Term: </Strong>
              {`${startMoment.format('M/D/YYYY')} - ${startMoment
                .clone()
                .add(1, 'y')
                .subtract(1, 'd')
                .format('M/D/YYYY')}`}
            </Text>
            <Text size={500}>
              <Strong size={500}>Billing Date: </Strong>
              {startMoment.format('M/D/YYYY')}
              {!isPortal && (
                <Icon
                  cursor="pointer"
                  marginLeft={8}
                  icon={['fas', 'pencil-alt']}
                  onClick={() => setIsSetDateDialogShown(true)}
                />
              )}
            </Text>
            {couponState && (
              <Text size={500} marginTop={8}>
                <Strong size={500}>Coupon Term: </Strong>
                {couponState.duration === 'repeating'
                  ? `${couponState.duration_in_months} months`
                  : capital(couponState.duration)}
              </Text>
            )}
          </Pane>
        </Card>
        <PaymentMethodCard
          required
          elevation={elevation}
          setPlaidState={setPlaidState}
          marginBottom={16}
          guarantorId={guarantorId}
          isDependent={isDependent}
        />
        {showCoupon ? (
          <CouponCard accountId={accountId} elevation={elevation} onRetrieve={(coupon) => setCouponState(coupon)} />
        ) : (
          <Button appearance="minimal" alignSelf="center" onClick={() => setShowCoupon(true)}>
            Apply Coupon
          </Button>
        )}
      </Pane>
    </>
  )
}

export default MemberPaymentFields

const getShownPrice = (basePrice: number = 0, term: Types.PlanTerm, coupon?: Types.Coupon | null) => {
  let shownPrice = basePrice
  if (coupon?.valid) {
    if (coupon?.amount_off) shownPrice = basePrice - coupon.amount_off / 100
    if (coupon?.percent_off) shownPrice = basePrice * (1 - coupon.percent_off / 100)
  }

  return (
    <span>
      {shownPrice === basePrice ? (
        `$${shownPrice.toFixed(2)}`
      ) : (
        <>
          <span style={{ textDecoration: 'line-through' }}>{`$${basePrice.toFixed(2)}`}</span>
          <span>{` $${shownPrice.toFixed(2)}`}</span>
        </>
      )}
      {` / ${term === Types.PlanTerm.ANNUAL ? 'yr' : 'mo'}`}
    </span>
  )
}
