import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as EverGreenLink } from 'evergreen-ui'

// TSUPDATE once Link Props are available from evergreen
export type Props = (
  | { to: string; href?: never; onClick?: never }
  | { to?: never; href: string; onClick?: never }
  | { to?: string; href?: never; onClick: () => void }
) & {
  children?: React.ReactNode
  inline?: boolean
  [prop: string]: any
}

const Link = ({ to, inline, children, target, ...props }: Props) => {
  props.cursor = 'pointer'
  props.textDecoration = props.textDecoration ?? 'none'
  if (inline) props.display = 'inline'

  return (
    <>
      {to ? (
        <ReactRouterLink
          to={to}
          style={props.height ? { height: props.height } : {}}
          rel={target ? 'noopener noreferrer' : undefined}
          target={target}
        >
          <EverGreenLink is={inline ? 'span' : 'div'} {...props}>
            {children}
          </EverGreenLink>
        </ReactRouterLink>
      ) : (
        <EverGreenLink {...props} target={target}>
          {children}
        </EverGreenLink>
      )}
    </>
  )
}

export default Link
