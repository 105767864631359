import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MutationFunctionOptions, FetchResult, MutationResult } from '@apollo/client'
import { ConfirmationResult } from 'firebase/auth'

import * as GraphqlTypes from './graphql-codegen'
import { NotificationChannel } from './graphql-codegen'
export * from './graphql-codegen'

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export enum SiteColor {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED'
}

export enum Product {
  MEMBERSHIP = 'MEMBERSHIP',
  PBT = 'PBT',
  AR = 'AR',
  OTHER = 'OTHER'
}

export type Coupon = {
  __typename: 'StripeCoupon'
  id: string
  valid: boolean
  duration: string
  duration_in_months: number | null
  amount_off: number | null
  percent_off: number | null
}

export enum PatientField {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  PERIO = 'perio',
  DOB = 'dob',
  ADDRESS = 'address'
}

export enum DependentPath {
  PLAN = 'PLAN',
  PROFILE = 'PROFILE',
  ENROLL = 'ENROLL'
}

export type SelfEnrollmentState = {
  start?: {
    email: string
  }
  details?: {
    firstName: string
    lastName: string
    phone: string
    dobMonth: number
    dobDay: number
    dobYear: number
    perio: boolean
  }
  plan?: {
    id: string
    name: string
    term: GraphqlTypes.PlanTerm
    price: number
  }
}

export type PayState = {
  practice?: {
    account: GraphqlTypes.PayPractice_account_public
    lookupGuarantor?: null | {
      firstName: string
      lastName: string
      phone?: string
      email?: string

      phoneConfirmationResult?: ConfirmationResult
      token?: string

      dobMonth?: number
      dobDay?: number
      dobYear?: number

      matches?: { code: string; firstName: string; lastName: string; hasBalance: boolean }[]
    }
  }

  guarantor?: GraphqlTypes.PayPatient_authenticateGuarantor_guarantor

  applePayAvailable?: boolean

  installmentTemplate: {
    id: string
    name: string
    periods: number
    interval: number
    intervalType: GraphqlTypes.InstallmentIntervalType
    allowACH: boolean
    allowCC: boolean
    adminFee: number
    downPayment: number
  } | null

  manual: number | null

  charge?: {
    id: string
    grossAmount: number
  }
}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export type UpdatePayState = (updatedState: RecursivePartial<PayState>) => void

// Provider

// eslint-disable-next-line
declare global {
  interface Window {
    _env_: Record<string, string>
  }
}

export interface Plan {
  id: string
  name: string
  annualPriceActive: boolean
  annualPrice: number | null
  monthlyPriceActive: boolean
  monthlyPrice: number | null
  status: GraphqlTypes.PlanStatus
  allTimeMemberCount: number
  stripe?: {
    createdAt: number
  } | null
}

export type PrimaryLink = {
  name: string
  path: string
  icon: IconProp
  headerLinks: {
    name: string
    path: string
    component: React.FC<any>
    fullWidth?: boolean
  }[]
}

// Icon will apply to the first link in the list
export type NavBlock = {
  path: string
  icon: IconProp
  disabled?: boolean
  links: NavBlockLink[]
}

export type NavBlockLink = {
  name: string
  path: string
  headerLinks: {
    name: string
    path: string
    component: React.FC<any>
    fullWidth?: boolean
    minRole?: GraphqlTypes.UserRole
  }[]
  disabled?: boolean
  count?: number
  onSelect?: () => void
}

export type ChargePatientState = {
  initialContactId?: string
  patient?: {
    id: string
    email?: string | null
    name: {
      first: string
      last: string
    }
  }
  invoice?: {
    amount?: number
    installments?: boolean
    downPaymentToggle?: boolean
    downPayment?: number
  }
  uploads?: {
    filename: string
    path: string
  }[]
  request?: {
    code: string
  }
}

export enum PlanTerm {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY'
}

export type UpdatePlanProp = (
  options?: MutationFunctionOptions<GraphqlTypes.UpdatePlan, GraphqlTypes.UpdatePlanVariables> | undefined
) => Promise<FetchResult<GraphqlTypes.UpdatePlan>>
export type UpdatePlanStatusProp = MutationResult<GraphqlTypes.UpdatePlan>

export enum OnboardingStep {
  START = 'START',
  PRACTICE = 'PRACTICE',
  OFFICES = 'OFFICES',
  DENTISTS = 'DENTISTS',
  BANK = 'BANK',
  SUMMARY = 'SUMMARY',
  COMPLETE = 'COMPLETE'
}

export enum GuarantorPath {
  OVERVIEW = 'OVERVIEW',
  BILLING = 'BILLING',
  PLANS = 'PLANS',
  PROFILE = 'PROFILE',
  DEPENDENTS = 'DEPENDENTS',
  PAYMENT_INFO = 'PAYMENT_INFO',
  ENROLL_MEMBER = 'ENROLL_MEMBER',
  ENROLL_INSTALLMENTS = 'ENROLL_INSTALLMENTS',
  NOTIFICATION = 'NOTIFICATION',
  CHARGE = 'CHARGE',
  START_OUTREACH = 'START_OUTREACH'
}

export enum GuarantorBillingPath {
  ACTIONS = 'ACTIONS',
  VISITS = 'VISITS',
  OUTREACH = 'OUTREACH',
  PROCESSED = 'PROCESSED'
}

export enum PlanType {
  MEMBERSHIP = 'MEMBERSHIP',
  INSTALLMENTS = 'INSTALLMENTS'
}

export enum GuarantorActionItem {
  NOTIFICATION = 'NOTIFICATION',
  OUTREACH = 'OUTREACH',
  CHARGE = 'CHARGE',
  ENROLL_MEMBER = 'ENROLL_MEMBER',
  ENROLL_INSTALLMENTS = 'ENROLL_INSTALLMENTS',
  PAYMENT_INFO = 'PAYMENT_INFO',
  PROFILE_INFO = 'PROFILE_INFO',
  PAY_PORTAL = 'PAY_PORTAL',
  RECEIPT_PORTAL = 'RECEIPT_PORTAL',
  SUNBIT_TEXT = 'SUNBIT_TEXT'
}

export type SendNotificationState = {
  step: 'channel' | 'template' | 'payment' | 'verifyAddress' | 'review' | 'complete'
  channel?: NotificationChannel
  templateId?: string
  letterEstimatedAt?: string
}

export type SendNotificationChannel = 'text' | 'email'

export type NotificationTemplateVariables = {
  accountName: string
  accountCode: string
  accountReplyPhone: string
  accountProgramName: string
  contactCode: string
  contactFirstName: string
  memberUrl: string
  paymentUrl: string
  balancePatientPortion: string
  smsPaymentLink?: string
}

export type BucketSummary = {
  l30: number
  l60: number
  l90: number
  g90: number
  total: any
}

export type SelectedBuckets = {
  allowL30: boolean
  allowL60: boolean
  allowL90: boolean
  allowG90: boolean
}
