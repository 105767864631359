import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import { Pane, Heading, Alert, Text, Avatar, Strong } from 'evergreen-ui'
import { Types, Icon, colors } from 'lib'

import { useModal } from 'components/modal-provider'
import { capital } from 'case'

export type Props = {
  count: number
  patients: Types.HomeDashboard_failedMembership_contacts[] | Types.HomeDashboard_failedInstallments_contacts[]
  label?: string
}

const FailedPayment = ({ count, patients, label }: Props) => {
  const history = useHistory()

  const showGuarantorSheet = useModal('guarantor')

  return (
    <Alert
      intent="danger"
      hasIcon={false}
      hoverElevation={1}
      cursor="pointer"
      onClick={() => history.push('/charges/failed')}
    >
      <Pane display="flex" alignItems="center">
        <Pane width="52px" display="flex" justifyContent="center" alignItems="center">
          <Icon icon={['fad', 'credit-card']} size="2x" marginRight={20} marginLeft={8} color="danger" />
        </Pane>
        <Pane flexGrow={1}>
          <Heading marginBottom={4}>Failed {label ? `${capital(label)} ` : ''}Payment Method</Heading>
          <Text color="muted">
            {count > 1 ? (
              <>
                You have <Strong>{count} patients</Strong> with failed {label ? `${label} ` : ''}payment methods
              </>
            ) : (
              <>
                You have <Strong>1 patient</Strong> with a failed {label ? `${label} ` : ''}payment method
              </>
            )}
          </Text>
        </Pane>
        <Pane display="grid" gridTemplateRows="auto" gridGap={6} gridAutoFlow="column" paddingX={24}>
          {patients.slice(0, 5).map(({ id, name: { first, last } }) => (
            <HoverAvatar
              key={id}
              name={`${first} ${last}`}
              color="red"
              size={28}
              onClick={(e: MouseEvent) => {
                e.stopPropagation()
                showGuarantorSheet({ guarantorId: id })
              }}
            />
          ))}
          {patients.length === 6 && <Avatar name="＋" color="red" size={28} />}
        </Pane>
        <Icon icon={['far', 'chevron-right']} color="danger" size="lg" marginRight={8} />
      </Pane>
    </Alert>
  )
}

export default FailedPayment

const HoverAvatar = styled(Avatar)`
  :hover {
    background-color: ${colors.red.base};
    span {
      color: white;
    }
  }
`
