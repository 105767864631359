import { Types, Button } from 'lib'
import { Pane, toaster, Tooltip } from 'evergreen-ui'
import { useMutation } from '@apollo/client'
import { WRITEBACK_CHARGE } from 'graphql/_charge-sheet'
import moment from 'moment'

const writebackButtonContent: Record<Types.TransactionWritebackStatus, React.ReactNode> = {
  [Types.TransactionWritebackStatus.FAILED]: 'Retry Writeback',
  [Types.TransactionWritebackStatus.PARTIALLY_FAILED]: null,
  [Types.TransactionWritebackStatus.INITIALIZED]: 'Post Initialized',
  [Types.TransactionWritebackStatus.PENDING]: 'Writeback Pending',
  [Types.TransactionWritebackStatus.SUCCEEDED]: null,
  [Types.TransactionWritebackStatus.SUCCEEDED_SOME_UNALLOCATED]: null
}

const WritebackButton = ({ charge }: { charge: Types.ChargeSheet_charge }) => {
  const [writebackMutation, writebackMutationStatus] = useMutation<
    Types.WritebackCharge,
    Types.WritebackChargeVariables
  >(WRITEBACK_CHARGE, {
    onCompleted: () => toaster.success(`Post initiated!`),
    onError: () => toaster.danger('Unable to post charge')
  })

  const transactionWritebackMetrics = charge.transaction.writebackMetrics
  const status = writebackMutationStatus.loading
    ? Types.TransactionWritebackStatus.INITIALIZED
    : transactionWritebackMetrics
    ? transactionWritebackMetrics.status
    : null

  const postingDateSetting = charge.contact.account.postingSettings.postingDate
  const isPostDateDepositDatePlusOne = postingDateSetting === Types.PostingDate.DEPOSIT_DATE_PLUS_ONE
  const isPostDateDepositDatePlusTwo = postingDateSetting === Types.PostingDate.DEPOSIT_DATE_PLUS_TWO

  const isPendingOrInitialized =
    status === Types.TransactionWritebackStatus.PENDING || status === Types.TransactionWritebackStatus.INITIALIZED

  const isFailed = status === Types.TransactionWritebackStatus.FAILED

  const startOfToday = moment().startOf('day')

  const isWritebackDisabledDueToPayoutDate =
    // If posting date is set to deposit date - disable if no payout date
    (postingDateSetting === Types.PostingDate.DEPOSIT_DATE && !charge.payoutDate) ||
    // If posting date is set to deposit date plus one day - disable if payout date is less than 24 hours ago, from start of current day
    (isPostDateDepositDatePlusOne && (!charge.payoutDate || moment(charge.payoutDate * 1000).isAfter(startOfToday))) ||
    // If posting date is set to deposit date plus two days - disable if payout date is less than 48 hours ago, from start of current day
    (isPostDateDepositDatePlusTwo &&
      (!charge.payoutDate || moment(charge.payoutDate * 1000).isAfter(startOfToday.clone().subtract(1, 'day'))))

  const displayButton = // only display writeback button if writeback status is null or failed/pending/initialized
    (status === null ||
      [
        Types.TransactionWritebackStatus.FAILED,
        Types.TransactionWritebackStatus.PENDING,
        Types.TransactionWritebackStatus.INITIALIZED
      ].includes(status)) &&
    charge.balanceAmount !== 0 // do not display writeback button if charge does not affect balance (eg. admin fee)

  return (
    <Tooltip
      content={
        isWritebackDisabledDueToPayoutDate
          ? `Current settings require payment ${
              isPostDateDepositDatePlusOne
                ? 'to have been deposited for 24 hours'
                : isPostDateDepositDatePlusTwo
                ? 'to have been deposited for 48 hours'
                : 'to deposit'
            }  before posting to PMS.`
          : isPendingOrInitialized
          ? 'Writeback is being processed.'
          : isFailed
          ? 'Failed to post charge. Retry?'
          : 'Writeback Charge to PMS'
      }
    >
      <Pane display={displayButton ? undefined : 'none'}>
        <Button
          intent={isFailed || isPendingOrInitialized ? 'none' : 'success'}
          height={48}
          justifyContent="center"
          disabled={isWritebackDisabledDueToPayoutDate || isPendingOrInitialized}
          width="100%"
          onClick={() => writebackMutation({ variables: { chargeId: charge.id } })}
          isLoading={writebackMutationStatus.loading}
          iconBefore={isPendingOrInitialized ? ['fad', 'clock'] : undefined}
        >
          {status ? writebackButtonContent[status] : 'Post Payment to PMS'}
        </Button>
      </Pane>
    </Tooltip>
  )
}

export default WritebackButton
