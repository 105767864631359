import { capital } from 'case'
import { Pane, Tooltip, Text } from 'evergreen-ui'
import { Icon, Types, colors, Card, InfoTooltip } from 'lib'

enum Status {
  MISSING = 'Missing',
  NEW = 'New',
  VERIFIED = 'Verified',
  DELIVERABLE = 'Deliverable',
  UNDELIVERABLE = 'Undeliverable'
}
export interface Props {
  contact: Types.contactIndicatorFields
  showDob?: boolean
  showPms?: boolean
}

const parseReason = (reason: string | null | undefined) => (reason ? ` (${capital(reason)})` : '')

const Inline = ({ showDob, showPms, contact }: Props) => {
  const { dob, isPMS } = contact

  const phone = getPhoneFields(contact)
  const email = getEmailFields(contact)
  const letter = getLetterFields(contact)

  return (
    <Pane display="flex" alignItems="center" gap={8}>
      {showPms && (
        <Tooltip content="PMS Record">
          <Icon icon={['fad', 'link']} color={isPMS ? colors.neutral.muted : colors.neutral.light} />
        </Tooltip>
      )}

      {showDob && (
        <Tooltip content={dob ? `${dob.month}/${dob.day}/${dob.year}` : 'Date of Birth Missing'}>
          <Text size={300} color={dob ? colors.neutral.muted : colors.neutral.light}>
            DOB
          </Text>
        </Tooltip>
      )}

      <Tooltip content={`${phone.status}${parseReason(phone.reason)}`}>
        <Icon icon={['fas', 'phone']} fontSize={12} color={phone.color} />
      </Tooltip>

      <Tooltip content={`${email.status}${parseReason(email.reason)}`}>
        <Icon icon={['fas', 'at']} fontSize={12} color={email.color} />
      </Tooltip>

      <Tooltip content={`${letter.status}${parseReason(letter.reason)}`}>
        <Icon icon={['fad', 'mailbox-flag-up']} fontSize={12} color={letter.color} />
      </Tooltip>
    </Pane>
  )
}

const CardComponent = ({ contact }: Props) => {
  const phone = getPhoneFields(contact)
  const email = getEmailFields(contact)
  const letter = getLetterFields(contact)

  const { reason: phoneReason } = phone
  const { reason: emailReason } = email
  const { reason: letterReason } = letter

  const lineHeight = 1.2

  return (
    <Card display="grid" gap={12} gridTemplateColumns="12px 1fr 1fr" elevation={0}>
      <Icon icon={['fas', 'phone']} color={phone.color} />
      <Text marginRight={4} lineHeight={lineHeight}>
        Mobile Phone
      </Text>
      <Text marginRight={4} lineHeight={lineHeight}>
        {phone.status}
        {phoneReason && <InfoTooltip content={phoneReason} marginLeft={8} icon={['fas', 'triangle-exclamation']} />}
      </Text>
      <Icon icon={['fas', 'at']} color={email.color} />
      <Text marginRight={4} lineHeight={lineHeight}>
        Email
      </Text>
      <Text marginRight={4} lineHeight={lineHeight}>
        {email.status}
        {emailReason && <InfoTooltip content={emailReason} marginLeft={8} icon={['fas', 'triangle-exclamation']} />}
      </Text>
      <Icon icon={['fad', 'mailbox-flag-up']} color={letter.color} />
      <Text marginRight={4} lineHeight={lineHeight}>
        Address
      </Text>
      <Text marginRight={4} lineHeight={lineHeight}>
        {letter.status}
        {letterReason && <InfoTooltip content={letterReason} marginLeft={8} icon={['fas', 'triangle-exclamation']} />}
      </Text>
    </Card>
  )
}

const getPhoneFields = ({ phone, phone_smsEnabled, phone_consent, latestSMSNotification }: Props['contact']) => {
  if (!phone) {
    return {
      status: Status.MISSING,
      color: colors.neutral.light
    }
  }
  if (phone_smsEnabled === false) {
    return {
      status: Status.UNDELIVERABLE,
      color: colors.red.base,
      reason: 'SMS Disabled'
    }
  }

  if (phone_consent === false) {
    return {
      status: Status.UNDELIVERABLE,
      color: colors.red.base,
      reason: 'Opted Out'
    }
  }

  if (!latestSMSNotification) {
    return {
      status: Status.NEW,
      color: colors.neutral.muted
    }
  } else {
    const { status, failureReason } = latestSMSNotification
    if (status === Types.NotificationStatus.DELIVERED) {
      return {
        status: Status.DELIVERABLE,
        color: colors.green.base
      }
    } else if (status === Types.NotificationStatus.FAILED) {
      return {
        status: Status.UNDELIVERABLE,
        color: colors.red.base,
        reason: failureReason ? capital(failureReason) : null
      }
    } else {
      return {
        status: Status.VERIFIED,
        color: colors.blue.base
      }
    }
  }
}

const getEmailFields = ({ email, latestEmailNotification }: Props['contact']) => {
  if (!email) {
    return {
      status: Status.MISSING,
      color: colors.neutral.light
    }
  }
  if (!latestEmailNotification) {
    return {
      status: Status.NEW,
      color: colors.neutral.muted
    }
  } else {
    const { status, failureReason } = latestEmailNotification
    if (status === Types.NotificationStatus.DELIVERED) {
      return {
        status: Status.DELIVERABLE,
        color: colors.green.base
      }
    } else if (status === Types.NotificationStatus.FAILED) {
      return {
        status: Status.UNDELIVERABLE,
        color: colors.red.base,
        reason: failureReason ? capital(failureReason) : null
      }
    } else {
      return {
        status: Status.VERIFIED,
        color: colors.blue.base
      }
    }
  }
}

const getLetterFields = ({ hasAddress, isAddressVerified, latestLetterNotification }: Props['contact']) => {
  if (!hasAddress) {
    return {
      status: Status.MISSING,
      color: colors.neutral.light
    }
  }
  if (isAddressVerified === false) {
    return {
      status: Status.UNDELIVERABLE,
      color: colors.red.base,
      reason: 'Address Unverified'
    }
  }

  if (!latestLetterNotification) {
    return {
      status: Status.NEW,
      color: colors.neutral.muted
    }
  } else {
    const { status, failureReason } = latestLetterNotification
    if (status === Types.NotificationStatus.DELIVERED) {
      return {
        status: Status.DELIVERABLE,
        color: colors.green.base
      }
    } else if (status === Types.NotificationStatus.FAILED) {
      return {
        status: Status.UNDELIVERABLE,
        color: colors.red.base,
        reason: failureReason ? capital(failureReason) : null
      }
    } else {
      return {
        status: Status.VERIFIED,
        color: colors.blue.base
      }
    }
  }
}

export default { Inline, Card: CardComponent }
