import { createContext, useContext } from 'react'

export const LibConfigContext = createContext({
  plaid: {
    env: '',
    publicKey: ''
  },
  sunbit: {
    mode: '',
    apiKey: ''
  }
})

type Config = {
  plaid: {
    env: string
    publicKey: string
  }
  sunbit: {
    mode: string
    apiKey: string
  }
}

export const useLibConfig = () => useContext(LibConfigContext)

export const LibConfigProvider = ({ children, config }: { children: React.ReactNode; config: Config }) => (
  <LibConfigContext.Provider value={config}>{children}</LibConfigContext.Provider>
)
