import { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import FirebaseProvider from 'components/firebase-provider'
import { useSigninCheck } from 'reactfire'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import ApolloProvider from 'components/apollo-provider'
import { useApolloClient } from '@apollo/client'
import { GlobalProvider } from 'components/global-provider'
import { GlobalDebouncedCallbackProvider } from 'components/global-debounced-callback'

import { MediaQueryProvider, FullScreen, TokenProvider, LibConfigProvider } from 'lib'

import LoginView from 'views/login'
import OnboardingView from 'views/onboarding'
import InvitationView from 'views/invitation'
import { ModalProvider } from 'components/modal-provider'

if (!window._env_.REACT_APP_STRIPE_KEY) {
  throw Error('Stripe key missing')
}

const Layout = lazy(() => import('components/layout'))

export default () => {
  return (
    // <React.StrictMode>
    <Suspense fallback={<FullScreen display="flex" justifyContent="center" alignItems="center" />}>
      <Router>
        <FirebaseProvider>
          <LibConfigProvider
            config={{
              plaid: {
                env: window._env_.REACT_APP_ENV === 'production' ? 'production' : 'sandbox',
                publicKey: window._env_.REACT_APP_PLAID_PUBLIC_KEY ?? ''
              },
              sunbit: {
                mode: window._env_.REACT_APP_ENV === 'production' ? 'prod' : 'sandbox',
                apiKey: window._env_.REACT_APP_SUNBIT_PUBLIC_KEY ?? ''
              }
            }}
          >
            <TokenProvider>
              <ApolloProvider>
                <GlobalDebouncedCallbackProvider>
                  <MediaQueryProvider>
                    <ApolloConsumer />
                  </MediaQueryProvider>
                </GlobalDebouncedCallbackProvider>
              </ApolloProvider>
            </TokenProvider>
          </LibConfigProvider>
        </FirebaseProvider>
      </Router>
    </Suspense>

    // </React.StrictMode>
  )
}

const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY)

const ApolloConsumer = () => {
  const { data: signInCheckResult } = useSigninCheck({ requiredClaims: { onboardingStep: 'COMPLETE' } })

  const apolloClient = useApolloClient()

  if (!signInCheckResult) return <div />

  return (
    <Elements stripe={stripePromise}>
      <Switch>
        <Route path="/onboarding">
          <ModalProvider apolloClient={apolloClient}>
            <OnboardingView />
          </ModalProvider>
        </Route>
        <Route path="/invitation">
          <ModalProvider apolloClient={apolloClient}>
            <InvitationView />
          </ModalProvider>
        </Route>

        <Route path="/">
          {signInCheckResult.signedIn ? (
            signInCheckResult.hasRequiredClaims ? (
              <GlobalProvider>
                <ModalProvider apolloClient={apolloClient}>
                  <Layout />
                </ModalProvider>
              </GlobalProvider>
            ) : (
              <Redirect to="/onboarding" />
            )
          ) : (
            <ModalProvider apolloClient={apolloClient}>
              <LoginView />
              <Redirect from="*" to="/" />
            </ModalProvider>
          )}
        </Route>
      </Switch>
    </Elements>
  )
}
